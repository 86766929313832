import React from 'react'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { useStaticQuery, graphql } from 'gatsby'

import * as S from './styled'

const Hero = () => {
  const data = useStaticQuery(
    graphql`
      query {
        hero: markdownRemark(frontmatter: { key: { eq: "images" } }) {
          frontmatter {
            hero {
              childImageSharp {
                fluid(maxWidth: 960, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        message: markdownRemark(frontmatter: { key: { eq: "banner" } }) {
          frontmatter {
            message
          }
        }
      }
    `
  )

  const image = data.hero.frontmatter.hero.childImageSharp.fluid
  const message = data.message.frontmatter.message
  return (
    <S.HeroWrapper>
      <S.TitleWrapper>
        <S.ImageMobile fluid={image}>
          <S.Container>
            <S.Title>{message}</S.Title>
            <AnchorLink to="/#contato">
              <button>Entre em contato</button>
            </AnchorLink>
          </S.Container>
        </S.ImageMobile>
        <S.Container className="desktop">
          <S.Title>{message}</S.Title>
          <AnchorLink to="/#contato">
            <button>Entre em contato</button>
          </AnchorLink>
        </S.Container>
      </S.TitleWrapper>
      <S.Image fluid={image} />
    </S.HeroWrapper>
  )
}

export default Hero
