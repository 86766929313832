import React from 'react'

import * as S from './styled'

const StaffCard = ({
  title,
  position,
  oab,
  specialty,
  whatsapp,
  image,
  link
}) => {
  const whatsappUrl = 'https://api.whatsapp.com/send?phone=55' + whatsapp
  const linkTo = '/advogados/#' + link

  return (
    <S.Card>
      <S.Link to={linkTo}>
        {image && <S.Image fluid={image.childImageSharp.fluid} />}
      </S.Link>
      <S.Description>
        <S.Link to={linkTo}>
          <S.DescriptionName>{title}</S.DescriptionName>
        </S.Link>
        <S.DescriptionPosition>
          {position} <span>{oab}</span>
        </S.DescriptionPosition>
        {/* <S.DescriptionSpecialty>{specialty}</S.DescriptionSpecialty> */}
        <div
          style={{
            position: 'absolute',
            right: '10px',
            bottom: '10px'
          }}
        >
          <a
            href={whatsappUrl}
            alt="WhatsApp"
            aria-label="WhatsApp"
            target="_blank"
            rel="noreferrer"
          >
            <S.WhatsappIcon />
          </a>
        </div>
      </S.Description>
    </S.Card>
  )
}

export default StaffCard
