import styled from 'styled-components'
import media from 'styled-media-query'

export const Partners = styled.div`
  display: flex;
  gap: 20px;

  ${media.lessThan('medium')`
    flex-direction: column
  `}
`
