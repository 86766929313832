import styled from 'styled-components'
import media from 'styled-media-query'
import BackgroundImage from 'gatsby-background-image'

export const HeroWrapper = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  background: #373737;

  ${media.lessThan('medium')`
    grid-template-columns: 2fr 1fr;
  `}

  ${media.lessThan('small')`
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  `}
`

export const TitleWrapper = styled.div``

export const Container = styled.div`
  text-align: right;
  padding: 10%;
  margin: 400px 0 300px;
  border-right: 4px solid #fff;
  box-shadow: 4px 0px 0px 0px #fff;
  z-index: 1;

  &.desktop {
    ${media.lessThan('small')`
      display: none;
    `}
  }

  ${media.lessThan('small')`
    text-align: center;
    margin: 0;
    padding: 250px 20px 160px;
    border: 0;
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.6);
  `}
`

export const Title = styled.h2`
  color: #fff;
  font-weight: 400;

  ${media.lessThan('small')`
    font-size: 1.5rem;
  `}
`

export const Image = styled(BackgroundImage)``

export const ImageMobile = styled(BackgroundImage)`
  ${media.greaterThan('small')`
    display: none;
  `}
`
