import styled from 'styled-components'
import media from 'styled-media-query'
import BackgroundImage from 'gatsby-background-image'

export const InfoWrapper = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;

  ${media.lessThan('medium')`
    grid-template-columns: 1fr;
  `}
`

export const InfoItens = styled.div`
  padding: 10%;

  ${media.lessThan('medium')`
    padding: 10% 20px;
  `}
`

export const InfoItem = styled.div`
  margin-bottom: 1rem;
`

export const Title = styled.h2``

export const InfoTitle = styled.h3`
  a {
    color: black;
    text-decoration: none;
  }
`

export const InfoTag = styled.span`
  font-weight: 300;
  font-size: 14px;
  text-transform: uppercase;
  color: #cfb53b;
`

export const InfoDate = styled.span`
  font-size: 12px;
`

export const Pagination = styled.span`
  font-size: 14px;
  float: right;
`

export const Image = styled(BackgroundImage)``
