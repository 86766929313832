import styled from 'styled-components'
import Img from 'gatsby-image'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { Whatsapp } from '@styled-icons/boxicons-logos/Whatsapp'

export const Link = styled(AnchorLink)`
  text-decoration: none;
`

export const Card = styled.div`
  background: white;
  border-radius: 15px 15px 10px 10px;
  width: 260px;
`

export const Image = styled(Img)`
  border-radius: 10px 10px 0 0;
  height: 285px;
`

export const Description = styled.div`
  position: relative;
  color: #707070;
  text-align: center;
  padding: 8px;

  a {
    color: #707070;
  }
`

export const DescriptionName = styled.h3`
  font-size: 16px;
  font-weight: 600;
  margin: 0;
`

export const DescriptionPosition = styled.p`
  font-size: 12px;
  font-weight: 600;
  margin: 8px;

  span {
    display: block;
    font-weight: 400;
  }
`

export const DescriptionSpecialty = styled.p`
  font-size: 14px;
  font-weight: 300;
  text-transform: uppercase;
  color: #cfb53b;
  margin: 0;
`

export const WhatsappIcon = styled(Whatsapp)`
  height: 19px;
  color: #cfb53b;
`
