import React from 'react'
import SwiperCore, { Pagination, Autoplay, A11y } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useStaticQuery, graphql } from 'gatsby'

import 'swiper/swiper-bundle.min.css'
import * as S from './styled'

const About = () => {
  const data = useStaticQuery(
    graphql`
      query {
        aboutText: markdownRemark(frontmatter: { key: { eq: "about" } }) {
          frontmatter {
            title
            first
          }
          html
        }
        aboutImage: markdownRemark(frontmatter: { key: { eq: "images" } }) {
          frontmatter {
            about {
              childImageSharp {
                fluid(maxWidth: 960, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `
  )

  SwiperCore.use([Pagination, Autoplay, A11y])

  return (
    <S.AboutWrapper id="sobre">
      <Swiper
        slidesPerView={1}
        autoplay
        pagination={{ clickable: true }}
        style={{ margin: '0' }}
      >
        {data.aboutImage.frontmatter.about.map((image, i) => (
          <SwiperSlide>
            <S.Image key={i} fluid={image.childImageSharp.fluid} />
          </SwiperSlide>
        ))}
      </Swiper>
      <S.TextWrapper>
        <S.Title>{data.aboutText.frontmatter.title}</S.Title>
        <div className="border" />
        <p>{data.aboutText.frontmatter.first}</p>
        <div
          dangerouslySetInnerHTML={{
            __html: data.aboutText.html
          }}
        />
      </S.TextWrapper>
      <S.TextWrapperMobile>
        <div style={{ padding: '60px 20px 0' }}>
          <S.Title>{data.aboutText.frontmatter.title}</S.Title>
          <div className="border" />
          <p>{data.aboutText.frontmatter.first}</p>
        </div>
        <S.Button to="/apresentacao">Saiba mais</S.Button>
      </S.TextWrapperMobile>
    </S.AboutWrapper>
  )
}

export default About
