import styled from 'styled-components'
import { Link } from 'gatsby'

export const Staff = styled.section`
  padding: 80px 0;
  background: #373737;
`

export const StaffWrapper = styled.div`
  margin: 0 auto;
  display: grid;
  justify-items: center;
`

export const Title = styled.h2`
  text-align: center;
`

export const LinkToPage = styled(Link)`
  color: #989898;
  text-decoration: none;
  margin-top: 40px;
  transition: 0.5s;

  &:hover {
    color: white;
  }
`
