import styled from 'styled-components'
import media from 'styled-media-query'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion'
import { Triangle } from '@styled-icons/ionicons-solid/Triangle'
import BackgroundImage from 'gatsby-background-image'

export const FieldWrapper = styled.section``

export const FieldTabs = styled(Tabs)`
  display: grid;
  grid-template-columns: 1fr 1fr;

  ${media.lessThan('medium')`
    grid-template-columns: 1fr;
  `}
`

export const FieldTabList = styled(TabList)`
  list-style-type: none;
  padding: 10%;

  ${media.lessThan('medium')`
    display: none;
  `}
`
export const Title = styled.h2`
  ${media.lessThan('medium')`
    padding: 60px 20px 0;
  `}
`

export const FieldTab = styled(Tab)`
  background: transparent;
  color: #686868;
  font-size: 1.2rem;
  font-weight: 700;
  text-align: right;
  padding: 20px 0;
  cursor: pointer;
  transition: 0.5s;

  &:hover {
    color: #cfb53b;
  }

  &:last-child {
    padding-bottom: 0;
  }

  &.react-tabs__tab--selected {
    color: #cfb53b;
  }
`

export const TriangleIcon = styled(Triangle)`
  width: 25px;
  margin-left: 30px;
  transform: rotateY(0deg) rotate(90deg);

  ${media.lessThan('medium')`
    width: 20px;
    transform: rotateY(0deg) rotate(180deg);
    float: right;
  `}
`

export const Image = styled(BackgroundImage)``

export const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.6);
  height: 100%;
`

export const FieldTabPanel = styled(TabPanel)``

export const TextWrapper = styled.div`
  color: white;
  padding: 10%;
  padding-top: 190px;

  ul {
    margin-left: 15px;
  }

  ${media.lessThan('medium')`
    display: none;
  `}
`

export const FieldAccordion = styled(Accordion)`
  ${media.greaterThan('medium')`
    display: none;
  `}
`

export const FieldAccordionItem = styled(AccordionItem)``

export const FieldAccordionItemHeading = styled(AccordionItemHeading)``

export const FieldAccordionButton = styled(AccordionItemButton)`
  background: white;
  color: #686868;
  text-align: left;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border-top: 1px solid #373737;
  border-radius: 0;
  outline: none;

  &:hover {
    color: #cfb53b;
    transition: 0.35s;
  }
`

export const FieldAccordionItemPanel = styled(AccordionItemPanel)`
  background: #fffcf3;
  padding: 20px;
  animation: fadeIn 0.35s ease-in;
  border-top: 1px solid #373737;

  ul {
    margin-left: 15px;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`
