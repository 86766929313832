import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Hero from '../components/Hero'
import About from '../components/About'
import Field from '../components/Field'
import Staff from '../components/Staff'
import Info from '../components/Info'
import Contact from '../components/Contact'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Hero />
    <About />
    <Field />
    <Staff />
    <Info />
    <Contact />
  </Layout>
)

export default IndexPage
