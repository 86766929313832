import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import StaffCard from '../StaffCard'
import * as S from './styled'

const Partners = () => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          filter: {
            frontmatter: { key: { eq: "staff" }, partner: { eq: true } }
          }
          sort: { fields: frontmatter___title, order: DESC }
        ) {
          edges {
            node {
              frontmatter {
                slug
                oab
                position
                specialty
                title
                whatsapp
                image {
                  childImageSharp {
                    fluid(maxWidth: 260, quality: 80) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  const cardItens = allMarkdownRemark.edges

  return (
    <S.Partners>
      {cardItens.map(({ node: cardItem }) => (
        <StaffCard
          key={cardItem.frontmatter.title}
          title={cardItem.frontmatter.title}
          position={cardItem.frontmatter.position}
          oab={cardItem.frontmatter.oab}
          specialty={cardItem.frontmatter.specialty}
          image={cardItem.frontmatter.image}
          whatsapp={cardItem.frontmatter.whatsapp}
          link={cardItem.frontmatter.slug}
        />
      ))}
    </S.Partners>
  )
}

export default Partners
