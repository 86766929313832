import React from 'react'

import Partners from '../Partners'
import Associated from '../Associated'
import * as S from './styled'

const Staff = () => (
  <S.Staff id="advogados">
    <S.StaffWrapper>
      <S.Title>Advogados</S.Title>
      <Partners />
      <Associated />
      <S.LinkToPage to="/advogados">
        Veja os currículos dos nossos advogados
      </S.LinkToPage>
    </S.StaffWrapper>
  </S.Staff>
)

export default Staff
