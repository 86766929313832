import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

import * as S from './styled'

const Info = () => {
  const data = useStaticQuery(
    graphql`
      query {
        info: markdownRemark(frontmatter: { key: { eq: "images" } }) {
          frontmatter {
            info {
              childImageSharp {
                fluid(maxWidth: 960, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        infoPosts: allMarkdownRemark(
          filter: { frontmatter: { key: { eq: "info" } } }
          limit: 8
          sort: { fields: frontmatter___date, order: DESC }
        ) {
          edges {
            node {
              frontmatter {
                title
                tag
                date(locale: "pt-br", formatString: "DD/MM/YYYY")
              }
              id
              fields {
                slug
              }
            }
          }
        }
      }
    `
  )

  const infoImage = data.info.frontmatter.info.childImageSharp.fluid
  return (
    <S.InfoWrapper id="informativos">
      <S.InfoItens>
        <S.Title>Informativos</S.Title>
        <div className="border" />
        {data.infoPosts.edges.map(({ node: infoPost }) => (
          <S.InfoItem key={infoPost.id}>
            <S.InfoTag>{infoPost.frontmatter.tag}</S.InfoTag>
            <S.InfoTitle>
              <Link to={infoPost.fields.slug} alt={infoPost.frontmatter.title}>
                {infoPost.frontmatter.title}
              </Link>
            </S.InfoTitle>
            <S.InfoDate>{infoPost.frontmatter.date}</S.InfoDate>
          </S.InfoItem>
        ))}
        {/* <S.Pagination>Próximo {'>'}</S.Pagination> */}
      </S.InfoItens>
      <S.Image fluid={infoImage} />
    </S.InfoWrapper>
  )
}

export default Info
