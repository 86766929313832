import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import * as S from './styled'

const Field = () => {
  const data = useStaticQuery(
    graphql`
      query {
        field: markdownRemark(frontmatter: { key: { eq: "images" } }) {
          frontmatter {
            field {
              childImageSharp {
                fluid(maxWidth: 960, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        fieldTexts: allMarkdownRemark(
          filter: { frontmatter: { key: { eq: "field" } } }
          sort: { fields: frontmatter___order, order: ASC }
        ) {
          edges {
            node {
              frontmatter {
                title
                order
              }
              html
              id
            }
          }
        }
      }
    `
  )

  return (
    <S.FieldWrapper id="areas-de-atuacao">
      <S.FieldTabs>
        <S.FieldTabList>
          <S.Title>Áreas de atuação</S.Title>
          <div className="border" />
          {data.fieldTexts.edges.map(({ node: fieldText }) => (
            <S.FieldTab key={fieldText.id}>
              {fieldText.frontmatter.title} <S.TriangleIcon />
            </S.FieldTab>
          ))}
        </S.FieldTabList>

        <S.Image fluid={data.field.frontmatter.field.childImageSharp.fluid}>
          <S.Overlay>
            {data.fieldTexts.edges.map(({ node: fieldText }) => (
              <S.FieldTabPanel key={fieldText.id}>
                <S.TextWrapper
                  dangerouslySetInnerHTML={{
                    __html: fieldText.html
                  }}
                />
              </S.FieldTabPanel>
            ))}
          </S.Overlay>
        </S.Image>

        <S.FieldAccordion allowZeroExpanded>
          <S.Title>Áreas de atuação</S.Title>
          <div className="border" style={{ marginLeft: '20px' }} />
          {data.fieldTexts.edges.map(({ node: fieldText }) => (
            <S.FieldAccordionItem key={fieldText.id}>
              <S.FieldAccordionItemHeading>
                <S.FieldAccordionButton>
                  {fieldText.frontmatter.title} <S.TriangleIcon />
                </S.FieldAccordionButton>
              </S.FieldAccordionItemHeading>
              <S.FieldAccordionItemPanel>
                <div
                  dangerouslySetInnerHTML={{
                    __html: fieldText.html
                  }}
                />
              </S.FieldAccordionItemPanel>
            </S.FieldAccordionItem>
          ))}
        </S.FieldAccordion>
      </S.FieldTabs>
    </S.FieldWrapper>
  )
}

export default Field
